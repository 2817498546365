*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:  'Open Sans', sans-serif;
}
.user-dashboard-heading{
    background: linear-gradient(90deg, #00AEEF 0%, #FFFFFF 100%);
  padding: 1.5vh;
  text-align: center;
}

.user-dashboard-heading h3{
  font-weight: 600;
  }
/* Sidebar styles */
.sidebar-user {
  width: 250px;
  background: linear-gradient(88.34deg, #00AEEF 2.64%, #FFFFFF 99.83%);
  left: 0;
  overflow: hidden;
}

.sidebar-user.collapsed {
  width: 80px; /* Collapsed width */
}

.menu-list-user {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu-item-container {
  display: flex;
  align-items: center;
  background: linear-gradient(88.34deg, #00AEEF 2.64%, #FFFFFF 99.83%);
}

.menu-item-user {
  background: transparent;
  width: 100%;
  height: 7vh;
  border: none;
  text-align: center;
  padding-left: 25px;
  display: flex;
  align-items: center;
  transition: padding 0.3s ease;
}

.menu-item-user .menu-icon {
  margin-right: 10px;
  font-size: 24px;
}

.menu-item-user:hover {
  background: linear-gradient(90deg, #000000 0%, #666666 49.5%);
  color: white;
  border-radius: 0px 30px 30px 0px;
 
}

.menu-item-user.active {
  background: linear-gradient(90deg, #000000 0%, #666666 49.5%);
  color: white;
  border-radius: 0px 30px 30px 0px;

}

.sidebar-user.collapsed .menu-item-user {
  width: 80px; /* Width of the button in collapsed mode */
  justify-content: flex-start;
  padding-left: 25px;
}

.sidebar-user.collapsed .menu-item-user span:not(.menu-icon) {
  display: none; /* Hide text in collapsed mode */
}

.toggle-sidebar-btn {
  display: block;
  width: 80px;
  padding: 5px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
}
.user-dashboard{
  display: flex;
  flex-direction: row;
  background-color: #EEEEEE;
  gap: 2vw;
  margin-bottom: 0;
}

.dashboard-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 230px;
  height: 140px;
  border-radius: 20px;
  padding: 15px;
  box-shadow: 5px 5px 5px #d3d3d3;
}

.dashboard-icon {
  font-size: 3.5rem;
  color: #d4cccc ;
  transition: color 0.3s ease; 
}

.dashboard-div:hover .dashboard-icon {
  color: #FFFFFF;
  font-size: 4rem;
}

.dashboard-text {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  color:white;
}

.dashboard-heading {
  font-size: 40px;
  font-weight: bold;
}

.dashboard-content {
  font-size: 1rem;
}

.dashboard-nav {
  height: 50px;
  padding: 10px;
  font-size: 24px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 5px 5px 10px #d3d3d3;
}

.nav-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.title {
  font-size: 24px;
}

.dashboard-content-cards{
  display:flex;
  flex-wrap: wrap;
  gap:2vw;
  min-width: 100%;
  padding: 2vh;
  margin-top: 10px;
}

.courses-enrolled{
  padding: 0px;
}

.courses-review{
  display:flex;
  justify-content: space-evenly;
  align-items: center;
}

.dashboard-card{
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
}

.resume-div{
  background-color: #FFFFFF;
  border: 1px solid #b3b3b3;
  box-shadow: 5px 5px 10px #d3d3d3;
  border-radius: 20px;
  padding: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 100%;
}

.resume{
  background-color: #FFFFFF;
  border: 1px solid #b3b3b3;
  box-shadow: 5px 5px 10px #d3d3d3;
  border-radius: 20px;
  padding: 4vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  min-width: 100%;
}

.resume-div-table{
  min-width: 100%;
   min-height: auto;
   padding-bottom: 20px;
 }

.resume-div-top{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
  padding-top: 1vh;
}
.resume-div-top p{
  padding: 10px;
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;
}

.resume-table{
min-width: 1300;
}

.invoice-icon {
  color: #00AEEF;
}

.edit-button {
  color: #00AEEF !important;
  font-size: 24px;
  transition: transform 0.3s ease;
}

/* Delete Button Styles */
.delete-button {
  color: red !important; 
  font-size: 24px;
  transition: transform 0.3s ease;
}

/* Table container styling */
.resume-div-table .MuiPaper-root {
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: none !important;
}

/* Table header cell styling */
.resume-table .MuiTableHead-root .MuiTableCell-root {
  background-color: #00AEEF !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: bold !important;
  padding: 15px !important;
  border-right: 1px solid white !important; /* Vertical line for header cells */
}

/* Remove right border from last header cell */
.resume-table .MuiTableHead-root .MuiTableCell-root:last-child {
  border-right: none !important;
}

/* Table body cell styling */
.resume-table .MuiTableBody-root .MuiTableCell-root {
  font-size: 16px !important;
  color: #000 !important;
  padding: 10px !important;
  border-right: 1px solid #e0e0e0 !important; /* Vertical line for body cells */
}

/* Remove right border from last cell in each row */
.resume-table .MuiTableBody-root .MuiTableCell-root:last-child {
  border-right: none !important;
}

/* Alternate row colors */
.resume-table .MuiTableBody-root .MuiTableRow-root:nth-of-type(odd) {
  background-color: #f5f5f5 !important;
}

.resume-table .MuiTableBody-root .MuiTableRow-root:nth-of-type(even) {
  background-color: #ffffff !important;
}

/* Hover effect for rows */
.resume-table .MuiTableBody-root .MuiTableRow-root:hover {
  background-color: #e0f7fa !important;
}

.resume-download-btn{
  padding: 5px 10px;
  background-color: #00AEEF;
  color: white;
  font-size: 24px;
  font-weight: 700;
  border-radius: 10px;
  border: 1px solid white;
  box-shadow: 5px 5px 5px #d3d3d3;
  margin: 2vh;
  white-space: nowrap;
}
.formgroup-checkbox{
  display: flex;
  justify-content: flex-start;
 margin-top: 3vh;
 padding: 10px;
font-size: 1rem;
font-weight: 600;
white-space: nowrap;
}

.button-div{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.explore-btn{
  padding: 5px 10px;
  background-color: #00AEEF;
  color: #FFFFFF;
  border-radius: 10px;
  border: none;
  box-shadow: 5px 5px 5px #d3d3d3;
  margin: 20px;
  font-size: 20px;
  font-weight: 700;
 /* margin-left: 500px; */
}

.write-btn{
  padding: 5px 10px;
  background-color: #00AEEF;
  color: #FFFFFF;
  border-radius: 10px;
  border: none;
  box-shadow: 5px 5px 5px #d3d3d3;
  margin: 20px;
  font-size: 20px;
  font-weight: 700;
}

.update-btn{
  padding: 5px 10px;
  background-color: #00AEEF;
  color: #FFFFFF;
  border-radius: 10px;
  border: none;
  box-shadow: 5px 5px 5px #d3d3d3;
  margin: 5px !important;
  font-size: 20px;
  font-weight: 700;
}

#exampleFormControlTextarea1 {
  max-width: 100%;
  min-width: 600px;
}
.form-control, .form-select{
  width: 100%;
  min-width: 150px;
  margin: 5px;
  height: 50px;
  border: 1px solid #b3b3b3;
}

.review-form{
  padding: 20px;
}

.user-dashboard-content {
flex-grow: 1;
padding: 0px;
}

.write-review {
 display: block;
 width: 100%;
 padding: 2vh;
 flex-direction: column;
  }

  .password-title {
    font-size: 24px;
    font-weight: 600;
    padding-top: 20px;
  }

  .row {
    display: block;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #nameInput, #emailInput, #locationInput, #genderInput, #oldPasswordInput, #newPasswordInput, #confirmPasswordInput, #extraFieldInput {
  width: 400px;
  height: 50px;
}

#mobileInput {
  width: 300px;
  height: 50px;
}

/* Flex layout to organize input fields in three rows with three columns */
.input-row {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.profile {
  padding: 10px;
  margin-right: 50px;
}

.me-3 {
 width: 100%;
  height: auto;
}

#inputNumber4 {
  margin-left: 0;
  border-radius: 0 5px 5px 0;
}

.add {
  display: flex;
  flex-direction: row;
  align-items: center;
 }

 .country-code-profile {
  display: flex;
  align-items: center;
  font-size: 20px;
  height: 50px;
  padding: 0px;
  width: 120px;
  border-radius: 5px 0 0 5px !important;
  border: 1px solid #b3b3b3 !important;
  display: flex; /* Ensure content is centered */
  align-items: center; /* Center icon and text vertically */
}

.country-flag {
  width: 20px;
  margin-right: 5px;
}


@media only screen and (max-width: 1366px) {
/* .sidebar-user {
  width: 250px;
} */

.menu-item-user {
  padding-left: 1.25vw;
  height: 6vh;
  font-size: 20px;
}

.resume-div {
  width: 70vw; /* Reduce width */
  padding: 1vh;
}

.resume {
  width: 70vw;
  padding: 1vh;
}

.input-row {
  gap: 5px;
}

.formgroup-checkbox {
  margin-top: 10px;
 }

}

@media only screen and (max-width: 1024px) {
/* .sidebar-user {
  width: 250px;
} */

.menu-item-user {
  padding-left: 1vw;
  height: 6vh;
}

.menu-item-user .menu-icon {
   margin-right: 10px;
  font-size: 20px;
}

.user-dashboard {
  gap: 1vw;
}

.user-dashboard-content {
  padding: 1vh;
}

#nameInput, #emailInput, #locationInput, #genderInput, #oldPasswordInput, #newPasswordInput, #confirmPasswordInput, #extraFieldInput {
  width: 300px;
  height: 50px;
}

#mobileInput {
  width: 250px;
  height: 50px;
}

.input-row {
  gap: 5px;
}
}
@media only screen and (max-width: 768px) {
/* .sidebar-user {
  width: 250px;
} */
.menu-item-user{
  font-size: 20px;
}
.menu-icon {
  font-size: 16px;
}

.dashboard-card {
  width: 75vw;
  margin: 0 auto; /* Center the content */
  overflow-x: auto; /* Allows horizontal scrolling if content is too wide */
  }

.resume-div {
  display: flex;
  align-items: flex-start;
  width: 75vw;
  border-radius: 20px;
  padding: 2vh;
  margin: 0 auto; /* Center the content */
  overflow-x: auto; /* Allows horizontal scrolling if content is too wide */
  }

  .resume {
    width: 75vw;
    padding: 2vh;
    margin: 0 auto; /* Center the content */
    overflow-x: auto; /* Allows horizontal scrolling if content is too wide */
    }

    .password-title {
      font-size: 16px;
      padding-top: 10px;
    }

    .formgroup-checkbox {
      margin-top: 10px;
     }

  .resume-download-btn{
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 10px;
  margin: 5px;
  }

  .resume-div-top p {
    font-size: 0.9rem; /* Adjust paragraph font size */
  }
  
  table {
    font-size: 10px; /* Smaller font size in table */
  }
}

/* For tablets */
@media (max-width: 1024px) {
  .dashboard-card {
    width: 67vw;
    padding: 1vh;
  }

  .resume-div {
  width: 67vw; /* Reduce width */
  padding: 1vh;
  font-size: 1.25rem;
}

.resume {
  width: 67vw;
  padding: 1vh;
  font-size: 1.25rem;
}

.password-title {
  font-size: 20px;
  padding-top: 15px;
}

.resume-download-btn {
  font-size: 16px; /* Reduce button font size */
  padding: 0.75vh;
  margin: 5px;
}

table {
  font-size: 12px; /* Reduce font size in table */
}
}

/* For very small screens (phones) */
@media (min-width: 350px) and (max-width: 480px) {

.user-dashboard-heading h3{
font-size: 16px;
}

/* Sidebar styles */
.sidebar-user {
  width: 200px;
  height: auto;
   transition: width 0.3s ease;
}

.sidebar-user.collapsed {
  width: 60px; 
}

.sidebar-user.collapsed .menu-item-user {
  width: 60px; 
  justify-content: flex-start;
  padding-left: 10px;
}

  .menu-item-user {
    background: transparent;
    width: 100%;
    height: 5vh;
    padding-left: 10px;
    transition: padding 0.3s ease, opacity 0.3s ease;
  }

.menu-item-user .menu-icon {
  margin-right: 10px;
  font-size: 18px;
}

.menu-item-container span {
  font-size: 14px;
}

.toggle-sidebar-btn {
    display: block;
    width: 50px;
    padding: 5px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
  }

  /* Dashboard cards */
  .dashboard-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 220px;
    height: 100px;
    border-radius: 20px;
    padding: 10px;
    box-shadow: 5px 5px 5px #d3d3d3;
  }
  
  .dashboard-icon {
    font-size: 2.5rem;
    color: #d4cccc ;
    transition: color 0.3s ease; 
  }
  
  .dashboard-div:hover .dashboard-icon {
    color: #FFFFFF;
    font-size: 3rem;
  }
  
  .dashboard-text {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    color:white;
    justify-content: space-between;
    padding: 5px 0;
  }
  
  .dashboard-heading {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .dashboard-content {
    font-size: 14px;
    padding-bottom: 10px;
    text-align: right;
  }
  
  .dashboard-nav {
    height: 50px;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 5px 5px 10px #d3d3d3;
  }

  .title {
    font-size: 16px;
  }

  .dashboard-card{
    min-width: 100%;
    padding: 5px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 0
  }
  
  .dashboard-content-cards{
    display: grid;
    justify-content: center;
    min-width: 90%;
    padding: 5px;
    gap: 10px;
  }

  .resume{
    min-width: 100%;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 0;
  } 
  /* Order page */
  .resume-div{
    min-width: 100%;
    border-radius: 10px;
    padding: 5px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 0;
  }
  
  .resume-div-table{
    min-width: 100%;
     min-height: 40vh;
     padding-bottom: 10px;
     overflow: scroll;
   }

.resume-div-top{
  display: flex;
  flex-direction: row;
  margin-left: 5px;
  align-items: center;
  gap: 10px;
  padding-top: 10px;
  flex-wrap: wrap;
}
.resume-div-top p{
  padding: 0px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.resume-table{
min-width: 500;
}

/* Table container styling */
.resume-div-table .MuiPaper-root {
  padding: 5px;
  border-radius: 10px;
}

/* Table header cell styling */
.resume-table .MuiTableHead-root .MuiTableCell-root {
  font-size: 14px !important;
  padding: 10px !important;
}

/* Table body cell styling */
.resume-table .MuiTableBody-root .MuiTableCell-root {
  font-size: 12px !important;
  padding: 10px !important;
}

.edit-button,
.delete-button {
  font-size: 20px !important;
}

.explore-btn{
  display: flex;
  align-items: center;
  white-space: nowrap;
  border-radius: 5px;
  margin: 10px;
  font-size: 14px;
}

.write-btn{
  display: flex;
  align-items: center;
  white-space: nowrap;
  border-radius: 5px;
  margin: 10px;
  font-size: 14px;
}

.row {
  display: flex;
  padding: 10px;
} 

.center {
  display: block;
}

.write-review {
  display: block;
  margin: 10px;
  padding: 0;
   }

.password-title {
  font-size: 16px;
  padding-top: 20px;
}

.update-btn{
  width: 100%;
  padding: 5px auto;
  border-radius: 5px;
  border: none;
  font-size: 14px;
}

#exampleFormControlTextarea1.form-control {
  max-width: 100%;
  min-width: 250px;
}

.input-row {
  display: flex;
  padding: 10px;
  padding-top: 0;
  margin-bottom: 0px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}

.country-code-profile {
  font-size: 16px;
  margin:0 0 15px 5px !important;
}

.profile {
  padding: 10px;
  margin-right: 0px;
}

table {
  font-size: 8px; /* Reduce table font size */
}

th, td {
  padding: 5px; /* Reduce padding in table cells */
}

.resume-download-btn {
  font-size: 14px; /* Further reduce button font size */
  padding: 5px 10px;
  border-radius: 5px;
}

.formgroup-checkbox {
 margin-top: 10px;
}

.formgroup-checkbox .custom-label{
  display: flex;
 margin-left: 10px;
 margin-top: 0;
font-size: 12px;
}

.select-menu .MuiSelect-select {
  font-size: 12px !important;
  padding: 5px;
  min-width: 200px;
}

}
table {
width: 100%; /* Make table take full width */
border-collapse: collapse; /* Ensure table borders collapse for cleaner look */
}

th, td {
text-align: center; /* Center align text */
padding: 10px;
white-space: nowrap; /* Prevent wrapping of table content */
}