*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:  'Open Sans', sans-serif;
}

.login{
    display: flex;
    flex-direction: row;
    font-style: Open-Sans;
}

.login-left{
    width: 55vw;
    height: 100%;
 
}
.login-with{
    width: 42vw;
    display: flex;
    justify-content: center;
    flex-direction: row;
}
.login-right{
   width: 45vw;
 height: 120vh;
   background-image: url('../../Assets/sider.png');
    background-repeat: no-repeat;
background-position: center;
background-size:cover;
    color: white;
   padding:5vh;
}

.welcome-back{
    margin-top: 2.5vh;
    font-weight:700;
    margin-bottom: 1vh;
    font-size:2.1rem;
}
.login-top{
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    padding-top: 10.9vh;
}

.form-floating img{
    position:absolute;
    bottom: 1vw;
    right:0.8vw;
    width:3vh;
    height:3vh;
   
}
.login-mid-name{
  padding-top: 6vh;
   }
   .icon-holder {
    display: flex;
    justify-content: space-evenly;
    gap: 4vw;
}

.social-login-btn {
    width: 4.5vh;
    height: 4.5vh;
    padding: 0; 
    background: none; 
    border: none; 
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-login-btn img {
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
}
.login-continue{
    font-size: 1.6rem;
    margin-bottom: 2vh;
    font-weight:400;
    color:rgba(0, 0,0,1);
}
.forgot-password{
    display: flex;
    justify-content: flex-end;
    font-size: 1rem;
    font-style: italic;
    font-weight: 500;
    color:#00AEEF;
    cursor: pointer;
    margin-right: 1% !important;
    margin-top: 15px;
}

.login-right-header{
    font-size: 2rem;
    font-weight: 400;
    padding-bottom:1.2vh;
}
.login-right-span{
    font-weight:bold;
}
.login-right-content{
    padding-bottom: 1.2vh;
   font-size: 1.5rem;
   font-weight: 400;
}

.login-option{
    display: flex;
    justify-content: center;
    font-size: 1rem;
    font-weight: 400;
    color: rgba(0,0,0,1);
   padding: 1.5vh;
   white-space: nowrap;
}
.go-to-register{
    padding: 1vh;
    font-size: 1.2rem;
    font-weight: 400;
}

  
.login .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1.5vh;
  }
  
  .login-label {
    margin-bottom: 0.5vh;
    font-size: 1rem;
    font-weight: 600;
  }
  
  .form-control {
    width: 500px;
    height: 50px;
    border-radius: 5px;
    margin-bottom: 0.5dvh; 
}
  
  .input-group {
    position: relative;
  }
  
  .input-group .form-control {
    width: 520px;
    height: 50px;
    margin-left: 0vh;
   border:1px solid #d3d3d3;
  }
  
  .input-group-text, .toggle-password {
    position: absolute;
    right: 1.8vw;
    top: 50%;
    color: #00AEEF;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  /* .input-group-text img {
    width: 30px;
    height: 30px;
  } */
  
  .form-check-input {
    color: black !important;
  
   font-size: 1.5rem;
  margin-top: 1vh;
  }
  .form-check-label{
    margin-top:0.4vh;
  }
  
  .forgot-password {
    font-size: 0.9rem;
    font-style: italic;
    color: #00AEEF;
    cursor: pointer;
    margin-top: 15px;
  }
  
  .captcha{
    margin-left: 2vw;
  }
.btn{
    width:26vw;
    padding: 1vh;
    font-weight: bold;
    border-radius: 5px;
}
.btn-close{
    height: 2.5vh;
    width:1.2vw;
    border-radius: 50%;
    border: 1px solid blue;
    color: blue;
}
@media (max-width: 1024px){
    .login {
        display: flex;
        justify-content: center;
        align-items: center;
      flex-direction: column !important;
        
      }
      .login-logo{
        height: 6vh;
      }
      .captcha{
        height: 40px;
        width: 40px;
      }
      .login-left {
        width: 100%;
       
      }
      .login-option{
        font-size: 0.8rem;
        padding-top: 0.35vh;
      }

  
      .login-right {
     width: 100%;
   
          
      }
      .social-login-btn{
        height: 50px;
        width: 50px;
      }
      .social-login-btn-linkedin{
        height: 55px;
        width: 55px;
      }
      .form-check{
        margin-top: 0vh;
        font-size: 1rem;
      }
      .input-group,
      .form-control,
      .btn {
        width: 520px;
        font-size: 1rem;
       height: 50px;
        border-radius: 5px;
        margin-bottom: 0.5vh;
      }
      .login-top {
        padding-top: 8vh;
      }
      .login-label{
        font-size: 1.15rem;
        margin-top: 1vh;
      }
      .forgot-password{
        margin-top: 15px;
      }
    
      .welcome-back {
        font-size: 2.25rem;
        margin-top: 1vh;
        margin-right: 4vh;
      }
    
      .login-continue {
        font-size: 1.5rem;
        margin-right: 4vh;
      }
    .login-with hr{
      width: 20%;
    }
    
      .icon-holder {
        justify-content:space-evenly;
        gap: 10vw;
      }
    
      .go-to-register {
        font-size: 1rem;
      }
}
@media (max-width: 768px) {
    .login {
      display: flex;
      justify-content: center;
      align-items: center;
    
      
    }
    .login-left {
        margin-top: 8vh;
        height: auto;
        padding: 1vh;
    }

    .login-right {
    display: none;
        
    }
    .login-logo{
        margin-left: 3vh;
    }
    .social-login-btn{
        height: 70px;
        width: 70px;
    }
    .social-login-btn-linkedin{
        height: 75px;
        width: 75px;
    }
    .input-group,
    .form-control,
    .btn {
      width: 70vw;
      font-size: 1rem;
      border-radius: 5px;
      
    }
    .login-top {
      padding-top: 1vh;
    }
    .login-label{
        margin-top: 1.5vh;
        font-size: 1.25rem;
    }
  
    .welcome-back {
      font-size: 2rem;
      margin-top: 2vh;
    }
  
    .login-continue {
      font-size: 1.25rem;
    }
  .login-with hr{
    width: 20%;
  }
  .forgot-password{
    margin-top: 15px;
  }
    .icon-holder {
      justify-content:space-evenly;
      gap: 10vw;
    }
  
    .go-to-register {
      font-size: 1rem;
    }
  }
  
  /* Small Mobile Phones */
  @media (min-width: 350px) and (max-width: 480px) {
    .login{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 6vh;
    }

    .input-group-text {
        position: absolute;
        right: 1.8vw;
        top: 50%;
       color: #00AEEF;
       transform: none;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }

    .captcha{
        margin-left: 2vh;
        height: 50px;
        width: 50px;
    }
    .login-option{
        font-size: 12px;
    }
    .forgot-password{
        margin-top: 10px;
        margin-bottom: 0;
        font-size: 12px;
        justify-content: flex-end;
        margin-right: 1%;
    }
      .login-left
       {
        width:100%;
        height: 100%;
        margin-top: 0px;
        padding: 5px !important;
      }
      .login-with hr{
        width: 20%;
        border: 1px solid #6A6A6A;
        background: #6A6A6A;
      }
      .login-right{
        display: none;
      }

    .login-top {
     margin-top: 1vh;
     padding-top: 1vh;
    }

    .login-label{
    margin-top: 0;
    font-size: 14px;
    }  

  .icon-holder{
    display: flex;
    justify-content: space-evenly;
   gap: 10vw;
  }
  .social-login-btn{
    height: 50px;
    width: 50px;
    margin-bottom: 5px;
  }
    .login-logo {
     height: auto;
    margin-left: 10%;
    justify-content: center;
    }
  
    .welcome-back {
      font-size: 32px;
      margin-top: 10px;
    }
  
    .login-continue {
      font-size: 20px;
    }
  
    .input-group,
    .form-control,
    .btn {
      width: 90vw;
      height: 50px;
      font-size: 14px;
      border-radius: 5px;
      margin-bottom: 20px !important;
      
    }
    .login-mid{
        gap:2vw;
    }
  
    .go-to-register {
      font-size: 12px;
      padding: 5px;
      margin-bottom: 10px;
    }

    .link-to-register{
        color:#00AEEF;
        text-decoration-line: none;
        font-weight: bold;
        font-size: 14px;
    }

    .register-btn {
        padding: 0.4vh 0.8vh;
        font-size: 20px;
        height: 50px;
        width: 90%;
        margin: 0;
    }
  }

.register-head{
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    font-size: 2.25rem;
}
.icon-places{
    padding: 3vh;
    gap: 3vh;
    display: flex;
    justify-content: space-evenly;
}
.icon-text{
   padding: 10px;
    width: 16.25vw;
display: flex;
flex-direction: row;
object-fit:fill;
cursor: pointer;

}

.icon-text-img{
    height: 50px;
    width: 50px;
    margin-right:-10px;
    z-index: 1;   
}

.icon-text-holder-google {
    margin-left: -5px;
    height: 5vh;
    width: 15vw;
    z-index: 0;
    border-radius: 5px;
    border: 1px solid black;
    color: black;
    margin-top: 1vh;
    font-weight: bold;
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-text:hover .icon-text-holder-google {
    font-size: 1.25rem;
    background-color: #d3d3d3;
}

    .icon-text-holder-facebook{
        height: 5vh;
        width: 15vw;
       z-index: 0;
       margin-left: -5px;
       border-radius:5px;
        margin-top: 1vh;
        font-weight: bold;
        font-size: 1.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #3B5998;
        color: white;
    }
    .icon-text:hover .icon-text-holder-facebook{
        font-size: 1.20rem;
        background-color: #588AF4;
    }
    .icon-text-holder-linkedin{
        height: 5vh;
        width: 15vw;
       z-index: 0;
       border-radius:5px;
       margin-left: -5px;
        margin-top: 1vh;
        font-weight: bold;
        font-size: 1.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #1275B1;
        color: white;
    }
    .icon-text:hover .icon-text-holder-linkedin{
        font-size: 1.20rem;
        background-color: #4e88be;
    }
    .icon-text-holder-apple{
        height: 5vh;
        width: 15vw;
       z-index: 0;
       border-radius:5px;
       margin-left: -5px;
        margin-top: 1vh;
        font-weight: bold;
        font-size: 1.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000000;
        color: white;
    }
    .icon-text:hover .icon-text-holder-apple{
        font-size: 1.20rem;
        background-color: #454545;
    }
    .star{
        color:red;
    }
    .icon-text-holder-email{
        height: 5vh;
        width: 15vw;
        z-index: 0;
        border-radius: 5px;
        margin-top: 1vh;
        margin-left: -5px;
        font-weight: bold;
        font-size: 1.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #EA4335;
        color: white;
       
    }
    .icon-text:hover .icon-text-holder-email{
        font-size: 1.20rem;
        background-color: #FF7F7F;
    }

.icon-places{
    display: flex;
    flex-direction: column;
   
}
.login-link{
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 1rem;
}
.link-to-register{
    color:#00AEEF;
    text-decoration-line: none;
    font-weight: bold;
}
@media (max-width: 1366px){
    .icon-text img{
        height: 75px;
        width: 75px;
        margin-top: 5px;
    }
    .icon-text-holder-google, .icon-text-holder-facebook, 
    .icon-text-holder-linkedin, .icon-text-holder-apple, 
    .icon-text-holder-email {
       height: 60px;
        width: 275px;
        font-size: 16px;
        margin-top: 13px;
        
    }
}
@media (max-width: 1024px) {
    .icon-places {
        padding-top: 1vh;
        flex-direction: column;
        gap: 2vh;
        width: 100%;
        align-items: center;
    }

    .icon-text {
    padding: 0.5vh;
    width: 40vw ;
    }

    .icon-text-img {
        height: 60px !important;
        width: 60px !important;
    }
    .icon-text-img-linkedin{
        height: 65px !important;
        width: 65px !important;
    }
    .icon-text-holder-google, .icon-text-holder-facebook, 
    .icon-text-holder-linkedin, .icon-text-holder-apple, 
    .icon-text-holder-email {
        padding: 1vh;
        width: 275px;
        font-size: 16px;
        height: 50px;
        margin-top: 14px;
        
    }

    .register-head {
        font-size: 1.6rem;
        margin-top: 0vh;
    }
    .option{
        margin-top: 0vh;
    }

    .login-link {
        font-size: 0.9rem;
    }
}

@media (max-width: 768px) {
    .login-left {
        width: 100%;
        height: auto;
        padding: 2vh;
    }

  
    .register-head {
        font-size: 1.5rem;
        text-align: center;
    }

    .icon-text {
        width: 80vw;
    }
.icon-text-img{
    height: 70px !important;
    width: 70px !important;
}
.icon-text-img-linkedin{
    height: 75px !important;
    width: 75px !important;
}
.icon-places{
    width: 100%;
}
.icon-text-holder-google, .icon-text-holder-facebook, 
.icon-text-holder-linkedin {
    padding: 0.5vh;
    height: 50px;
    font-size: 16px;
    padding-left: 2vw;
    
    
}
 .icon-text-holder-apple, 
.icon-text-holder-email {
padding: 1vh;
  height: 50px;
    font-size: 16px;
    padding-left: 2vw;
    
    
}

.icon-text-img-linkedin{
    height: 70px;
    width: 70px;
}
  
    .login-left h4 {
        font-size: 1.25rem;
        text-align: center;
    }

    .login-link {
        font-size: 1.25rem;
        text-align: center;
    }
}
@media (max-width: 480px) {
    .register-head {
        font-size: 30px;
        white-space: nowrap;
      /* margin-right: 10px; */
        margin-top: 10px;
        padding-top: 10px;
        padding-bottom: 0;
    }
    .option{
        font-size: 16px;
        /* margin-right: 1vh; */
        
    }
    .icon-text{
        width: 300px;
        height: 70px;
        align-items: center;
    }
  .icon-text-holder-apple,.icon-text-holder-google,.icon-text-holder-email,
  .icon-text-holder-facebook,.icon-text-holder-linkedin{
    width: 250px;
    height: 50px;
    font-size: 16px;
    margin-top: 5px;
  }
  .icon-text-img{
    height: 60px !important;
    width: 60px !important;
  }

.login-link{
    font-size: 12px;
}

.link-to{
    font-size: 12px !important;
}
 
}

.icon-places {
    padding: 3vh;
    display: flex;
    justify-content: space-evenly;
}

.icon-text {
    height: 70px;
    width: 325px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

/* .icon-text-img {
    height: 6.5vh;
    width: 3.25vw;
    margin-right: -8px;
    z-index: 1;
} */



/* .login-link {
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 1rem;
} */
.link-to{
    color:#00AEEF;
    text-decoration-line: none;
    font-weight: bold;
    font-size: 1rem;
}
.form-error{
    color:red;
    font-size:0.8rem;
    font-weight:bold;
}
.register-learning{
    font-size: 2.25rem;
    margin-top: 2vh;
    margin-bottom: 1vh;
}
.steps {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1.5vh 0;
    width: 100%;
    max-width: 17vw; 
    margin: 0 auto;
}

.step-one, .step-two {
    height:30px;
    width: 30px; 
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    padding: 5px;
    padding-top: 2vh;
}

.step-one {
    background-color: #00AEEF;
    color: white;
}

.step-two {
    background-color: #D9D9D9;
    color: black;
}

.steps-head {
    font-size: 1.25rem;
    font-weight: 500;
    padding-right: 1vw; 
}

.steps-head-one {
    color: white;
}

.steps-head-two {
    color: black;
}

hr {
    border: 1px solid #6A6A6A;
    height: 2px;
    background: #6A6A6A;
    width: 35%;
    margin: 0 auto; 
}

.register-link-to{
    text-decoration-line: none;
}

.country-dropdown {
    display: flex;
    align-items: center;
    font-size: 20px;
    height: 50px;
    padding: 0 6px;
    width: 120px;
    border-color: #d3d3d3 !important;
    display: flex; /* Ensure content is centered */
    align-items: center; /* Center icon and text vertically */
  }

@media screen and (max-width: 1024px) {
    .login {
        flex-direction: row;
    }

    .steps {
        max-width: 25vw;
    }
   
.steps-head hr{
    width: 50% !important;
}
    .register-learning {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 768px) {

   
    .register-btn {
        padding: 5px 10px;
        font-size: 1rem;
    }

    .register-learning {
        font-size: 1.25rem;
    }

    .steps {
        flex-direction: row;
        align-items: center;
        padding: 2vh 0;
        max-width: 40vw;
    }
    .steps-head hr{
        width: 50%;
    }

    .step-one, .step-two {
        height:30px;
        width: 30px; 
    }

    .steps-head {
        font-size: 1rem;
        padding-right: 0;
    }

    .steps-head-one, .steps-head-two {
        font-size: 1rem;
    }
    .steps-head hr{
        width: 80%;
    }

}

@media (min-width: 350px) and (max-width: 480px) {
   

    .steps {
        flex-direction: row;
        align-items: center;
        padding: 10px 0;
        max-width: 60%;
    }

    .step-one, .step-two {
        height: 28px;
        width: 28px;
    }

    .steps-head {
        font-size: 16px !important;
        padding-right: 5px;
        margin-bottom: 0;
    }

    .steps-head-one, .steps-head-two {
        font-size: 16px;
        margin-bottom: 5px;
        padding: 2px;
    }

    hr {
        width: 80%;
    }

    .register-learning{
        font-size: 30px;
        margin-top: 10px;
        margin-bottom: 0;
        white-space: nowrap;
    }

    .login-mid-name{
    padding-top: 15px;
    }

    .login-with-hachion{
        font-size: 12px !important;
    }
}
.enter-otp{
   display:flex;
   justify-content: flex-start;
   padding-top: 1vh;
   font-weight:bold;
}
.otp-verify{
    padding: 2vh;
}
.otp-number{
height: 50px;
width: 50px;
border:1px solid #6A6A6A;
border-radius: 10px;
box-shadow: darkgrey;
display: flex;
align-items: center;
justify-items: center;
}

.otp{
   /* width: 20vw; */
    height: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.register-btn{
    width: 520px;
    height: 50px;
    background-color: #00AEEF;
    color: white;
    font-weight: bold;
    font-size: 20;
    margin:1vh;
    border:0px;
    border-radius: 5px;
}
.otp-number:focus {
    border-color: #00AEEF;
    outline: none;
    box-shadow: 0 0 5px #00AEEF;
}

.floatingInputLabel{
    color:#D3D3D3;
}
.login-with-hachion{
    font-size: 1.1rem;
}
.login-label-password{
    display: flex;
    justify-content: flex-start;
}
#register.mobilenumber{
    width: 430px;
    height: 50px;
    border-radius: 0 5px 5px 0;
    outline: none;
    border: 1px solid #d3d3d3;
    padding: 10px;
   }
   
@media screen and (max-width: 1024px) {
    .login {
        flex-direction: row;
    }
.enter-otp{
    padding-top: 0vh;
}
    .register-btn {
        width: 100%;
        height: 50px;
        font-size: 1rem;
    }
    .otp{
        width: 40vw;
        height: 5vh;
    }
    .otp-verify{
        margin-top: 0vh;
    }
    .otp-number{
        height: 50px;
        width: 50px;
    }
}

@media screen and (max-width: 768px) {
    .otp-number {
        height: 50px;
        width: 50px;
    }

    .register-btn {
        font-size: 0.9rem;
    }
    .otp{
        width: 50vw;
    }
    .otp-number{
        height: 50px;
        width: 50px;
    }
}

@media screen and (max-width: 480px) {
    .otp-verify{
        margin-top: 0vh;
        padding: 10px;
    }
   
    .otp {
        width: auto;
        height: 50px;
        justify-content: space-between;
        gap: 20px;

    }
    /* .otp{
        width: 60vw;
    } */
    .otp-number{
        height: 50px;
        width: 50px;
        border-radius: 10px;
    }
    .country-dropdown {
        width: 150px;
      }

    /* .register-btn {
        font-size: 0.85rem;
        height: 5.5vh;
    } */
}