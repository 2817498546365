* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}
.admin-login{
background-color: #00AEEF;
color: white;
border-radius: 10px;
border: none;
padding: 10px;
}

.admin-nav {
  height: 8vh;
  background: linear-gradient(45deg,#006489,#00AEEF);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 2vh;
}

.search-div {
  height: 40px;
  width: 25.4vw;
  border-radius: 30px;
  display: flex;
  background-color: white;
  box-shadow: none;
  margin-left: 4vh;
}

.btn-category:hover, .search-div:hover .btn-search, .filter:hover {
background: linear-gradient(45deg, #006489, #00AEEF);
}

.search-input {
  flex: 1;
  padding-left: 1vw;
  border-radius: 30px 0px 0px 30px;
border: none;
  background-color: white;
  outline: none;
}

.btn-search {
  width: 70px;
  height: 40px;
  background-color: rgba(0, 174, 239, 1);
  color: white;
  border-radius: 0px 30px 30px 0px;
  cursor: pointer;
  /* margin-top: 1.25px; */
  /* margin-right: 1px; */
  border:none;
  font-size: 24px;
}

.admin-nav-middle {
  display: flex;
  flex-direction: row;
 margin-left: 4vw;
}

.admin-nav-right {
  display: flex;
  flex-direction: row;
  align-items: center;
 margin-left: 30vw;
 gap: 2vw;
  
}

.icon-container {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5vw;
}

.user-info {
  display: flex;
  align-items: center;
  
}

.image-block {
  height: 3.2vh;
  width: 1.7vw;
  border-radius: 50%;
  border: 2px solid white;
  overflow: hidden;
 
}

.image-block img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.btn-logout {
  background-color: #00AEEF;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  height:8vh !important;
  width:10vw;
  padding-top: 3vh !important;

}

.btn-group {
  display: flex;
  align-items: center;
  
}

.dropdown-menu {
  padding-top: 5px;
}

.dropdown-divider {
  margin: 0;
}

.dropdown-icon-admin {
color:#00AEEF;
}

.dropdown-item:hover .dropdown-icon-admin {
color: white;
}
/* Sidebar when collapsed */
.sidebar-admin.collapsed {
width: 100px; /* Sidebar width when collapsed */
}

/* Toggle button for collapsing the sidebar */
.sidebar-toggle {
display: flex;
justify-content: center;
padding: 10px;
}

.toggle-btn {
background: none;
border: none;
color: #ecf0f1;
font-size: 24px;
cursor: pointer;
}

/* Default sidebar styles */
.sidebar-admin {
background: linear-gradient(90deg, #006489 0%, #00AEEF 100%);
color: white;
left: 0;
right: 5px;
overflow-y: auto; /* Vertical scrolling */
height: 100vh;
margin: 0;
transition: width 0.3s ease, background-color 0.3s ease; /* Smooth transition */
}

/* Sidebar heading */
.sidebar-heading-admin {
display: flex;
justify-content: flex-start;
/* background: linear-gradient(90deg, #006489 0%, #00AEEF 100%); */
color: white;
font-size: 2rem;
font-weight: 600;
text-align: center;
padding: 2vh;
transition: opacity 0.3s ease;
margin-bottom: 0;
/* border-bottom: 1px solid #ffffff; */
}

.heading-admin {
display: flex;
flex-direction: row;
justify-content: space-between;
border-bottom: 1px solid #ffffff;
}

/* Hide heading when sidebar is collapsed */
.sidebar-admin.collapsed .sidebar-heading-admin {
opacity: 0;
visibility: hidden;
}

/* Menu list styles */
.menu-list-admin {
list-style-type: none; /* Remove bullet points */
padding: 0;
margin: 0;
}

/* Admin content area */
.admin-right {
flex-grow: 1;
padding: 20px;
width: 100%;
overflow-y: auto;
height: 85vh;
overflow-x: auto;
}

/* Menu item container */
.menu-item-container-admin {
display: flex;
align-items: center;
background: linear-gradient(90deg, #006489 0%, #00AEEF 100%);
}

/* Menu item style */
.menu-item-admin {
background: transparent;
color: white;
width: 300px;
border: none;
text-align: left;
padding: 1.5vh;
display: flex;
align-items: center;
transition: background-color 0.3s ease, border-radius 0.3s ease; /* Smooth transition */
}

/* Icon inside menu item */
.menu-item-admin .menu-icon {
margin-right: 10px;
color: white;
margin-top: 5px;
font-size: 24px;
}

/* Hover effect on menu item */
.menu-item-admin:hover {
background-color: #0D6EFD; /* Hover background color */
color: white;
border-radius: 0px 30px 30px 0px;
border: 1px solid white;
}

/* Active state of menu item */
.menu-item-admin.active {
background-color: #0D6EFD; /* Active state background color */
color: white;
border-radius: 0px 30px 30px 0px;
border: 1px solid white;
}

/* Sidebar collapsed state styles */
.sidebar-admin.collapsed .menu-item-admin {
padding: 1vh;
}

.sidebar-admin.collapsed .menu-title {
display: none; /* Hide text when collapsed */
}

.sidebar-admin.collapsed .menu-icon {
margin: 0 auto;
}

.sidebar-admin.collapsed .menu-item-admin {
color: #2c3e50; /* Change text color when collapsed */
}

/* Optional: Transition for collapsing sidebar */
.sidebar-admin.collapsed .sidebar-heading-admin {
opacity: 0;
visibility: hidden;
}

.admin-dashboard-content{
  display:flex;
  flex-wrap: wrap;
  gap:2vw;
  min-width: 75vw;
}

.dashboard-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 230px;
  height: 140px;
  border-radius: 20px;
  padding: 15px;
  box-shadow: 5px 5px 5px #d3d3d3;
}

.dashboard-icon {
  margin-right: 15px;
}

.dashboard-text {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  color:white;
}

.dashboard-heading {
  font-size: 1.5rem;
  font-weight: bold;
}

.dashboard-content {
  font-size: 1rem;
}
.category{
  min-width: 70vw;
}
.category-header{
width: 100%;
  background-color: #00AEEF;
  color: white;
  text-align: center;
  font-weight: 400;
  font-size: 1.5rem;
  padding:1.5vh;
  border-radius: 10px 10px 0px 0px;
}
.date-schedule{
  padding: 1.5vh;
  border: 1px solid #B3B3B3;
  box-shadow: 5px 5px 10px #d3d3d3;
  border-radius: 0px 0px 20px 20px;
 align-items: center;
 display: flex;
 flex-direction: row;
 gap: 3vh;
}
.filter{
  /* width:4vw; */
  background-color: #00AEEF;
  border-radius: 30px;
  padding: 8px 20px;
  box-shadow: 2px 2px 4px #d3d3d3;
  border: none;
  color: white;
  font-size:1rem;
}
.btn-number{
  /* width: 3.5vw; */
  border-radius: 10px;
  border: 1px solid #6A6A6A;
  padding: 5px 15px;
  background-color: white;

}
.entries{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5vh;
 
}
.btn-category{
  background-color: #00AEEF;
  color: white;
  border-radius: 30px;
  padding: 8px 20px ;
  border: 1px solid white;
  box-shadow: 2px 2px 4px #d3d3d3;
}
.entries-left{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2vh;
}
.entries-right{
  display: flex;
  justify-content: flex-end;
  gap: 2vh;
}
.edit{
  font-size: 1.5rem;
  color: #00AEEF;
  cursor: pointer;
}
.delete{
  font-size: 1.5rem;
  color: red;
  cursor: pointer;
}
.approve{
  font-size: 1.5rem;
  color: green;
  cursor: pointer;
}
.reject{
  font-size: 1.5rem;
  color: red;
  cursor: pointer;
}
.approved{
  font-size: 16px;
  background-color: green;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 10px;
}
.rejected{
  font-size: 16px;
  color: #ffffff;
  background-color: red;
  padding: 5px 10px;
  border-radius: 10px;
}
.submit-btn{
  font-size: 20px;
  height: 50px;
  padding: 5px 25px;
}
.submit-btn:hover{
  background-color: #0D6EFD;
}
.reset-btn{
  font-size: 20px;
  height: 50px;
  width: 90px;
  margin-top: 20px;
  background-color: white;
  border: 3px solid #00AEEF;
  color: #00AEEF;
  border-radius: 10px;
  box-shadow: 5px 5px 10px #d3d3d3;
 margin-left: 9vw;
}
.reset-btn:hover{
  border: 3px solid #0D6EFD;
  color: #0D6EFD;
}
.certificate-tabs {
  display: flex;
  margin-bottom: 20px;
}

.tab-item {
  cursor: pointer;
  padding: 10px 20px;
  margin-right: 10px;
  border-bottom: 3px solid transparent;
  transition: border-bottom 0.3s ease;
}

.active-tab {
  border-bottom: 3px solid blue; /* Blue line under active tab */
}
.pagination{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.download-btn{
  background-color: #00AEEF;
  border-radius: 30px;
  border: 1px solid white;
  box-shadow: 2px 2px 4px #d3d3d3;
  color: white;
  padding: 1vh;
}
.upload-btn{
  background-color: #00AEEF;
  border-radius: 10px;
  border: 1px solid white;
  box-shadow:5px 5px 5px #d3d3d3;
  color: white;
  padding: 2vh 3vw;
}
.lead-form{
  display: flex;
  align-items: center;
  justify-content: center;
}
.lead-form p{
  padding-left: 2vw;
}
.lead-form button{
  margin-left: 9vw;
}
.form-control{
min-width: 280px !important;
width: 100%;
}
.row{
gap: 40px;
}
#inputTime4{
width: 240px;
}
.row{
flex-direction: row;
}
/* Custom input field width */
/* .MuiInputBase-root {
width: 350px;
} */
.pagination {
display: flex;
align-items: center;
justify-content: center;
margin-top: 20px;
}

.pagination-arrow {
background-color: #f0f0f0;
border: none;
cursor: pointer;
padding: 3px;
width: 40px;
height: 40px;
}

.pagination-arrow:disabled {
cursor: not-allowed;
opacity: 0.5;
}

.pagination-number {
width: 40px;
height: 40px;
padding: 10px;
border: 1px solid #ddd;
cursor: pointer;
border-radius: 50%;
}

.pagination-number.active {
background-color: #00aeef;
color: white;
font-weight: bold;
}

.pagination-number:hover {
background-color: #0D6EFD;
color: white;
font-weight: bold;
}

.course-row{
display: flex;
flex-direction: row;
justify-content: space-between;
gap: 20px;
}
.course-details{
box-shadow: 5px 5px 10px #d3d3d3;
margin: 10px;
padding: 20px;
box-sizing: border-box;
border-radius: 20px;
}
.col-md-4 {
flex: 1 1 calc(33.33% - 20px); 
max-width: calc(33.33% - 20px); 
box-sizing: border-box; 
}

.course-mode {
flex: 1 1 calc(25% - 20px); /* Each box takes 25% width minus gap */
max-width: calc(25% - 20px);
box-shadow: 5px 5px 10px #d3d3d3;
margin: 10px 0; /* Vertical margin for spacing */
padding: 10px;
box-sizing: border-box;
border-radius: 20px;
}

.col-md-3 {
flex: 1 1 calc(33.33% - 10px); /* Input fields take one-third width */
margin: 5px 0; /* Reduces vertical spacing */
}

.form-control {
width: 100%; 
/* min-width: 0;  */
padding: 10px;
box-sizing: border-box;
}

.form-label {
padding: 3px;
white-space: nowrap;
}

.checkbox-group {
display: flex;
align-items: center;
gap: 8px;
margin-bottom: 8px;
}

.form-control-mode, .form-control-sample {
width: 100%; /* Reduces the input field width */
min-width: 180px;
height: 50px;
border-radius: 5px;
outline: none;
border: 1px solid #b3b3b3;
padding: 5px; /* Less padding for compact look */
box-sizing: border-box;
}

.description-text {
white-space: pre-wrap;
word-break: break-word;
font-family: inherit;
margin: 0;
}

.schedule-input {
width: 100%;
height: 50px;
border-radius: 5px;
outline: none;
border: 1px solid #b3b3b3;
padding: 5px; /* Less padding for compact look */
box-sizing: border-box;
margin-bottom: 10px;
}

.form-select {
width: 100%;
padding: 10px;
box-sizing: border-box;
}
.course-col{
display: flex;
flex-direction: row;
justify-content: center;
gap: 50px;
}
#exampleFormControlTextarea1{
height: 150px;
width: 1100px;
}
.dialog-box{
width: 100%;
display: flex;
justify-content: center;
}
.dialog-title{
background: linear-gradient(45deg,#006489,#00AEEF);
color: white;
font-size: 24px;
font-weight: 500;
margin-bottom: 20px;
display: flex;
justify-content: space-between !important;
white-space: nowrap;
width: 600px;
border-radius: 20px 20px 0 0;
}
.update-btn{
width: 150px;
background-color: #00AEEF !important;
color: white !important;
font-size: 24px;
font-weight: 700 !important;
border-radius: 10px;
box-shadow: 5px 5px 10px #d3d3d3;
align-items: center;
}

.table-input{
margin-top: 5px;
height: 55px;
border-radius: 5px;
border: 1px solid #d3d3d3;
outline: none;
padding: 5px;
}
.table-curriculum{
width: 100%;
/* margin-top: 5px; */
height: 100%;
min-height: '50px';
border: none;
outline: none;
padding: 10px;
box-sizing: "border-box";
font-size: 16px;
white-Space: 'pre-wrap';
overflow-Wrap: 'break-word';
word-Wrap: 'break-word';
 resize: 'none';
}
.bullet-list {
margin: 0;
padding-left: 5px;

list-style-type: disc;
}

.bullet-list li {
margin-bottom: 5px; /* Optional spacing between list items */
}