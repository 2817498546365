*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
  font-family:  'Open Sans', sans-serif;
}
.sidebar {
  flex: 1;
  width: 420px;
  background-color: white;
  color: black;
  min-width: 420px;
  left: 0;
  right:5px;
  overflow-y: auto;
  height:1000px;
  border:1 px solid rgba(0, 174, 239, 1);
  margin: 0px;
  margin-left: 5vw;
  border-radius:10px;
  border-top-right-radius: 10px;
  margin-top: 20px;
}

.sidebar-right-container {
  flex: 3; /* Adjust the flex value to your needs */
  display: flex;
  flex-direction: column; /* Make the course-pagination sit below the cards */
  align-items: center; /* Center align course-pagination */
  padding: 10px; /* Add some padding for better aesthetics */
}

.course-card-container {
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap on smaller screens */
  justify-content: flex-start; /* Align cards from the start */
  gap: 10px; /* Optional, adds space between the cards */
  width: 100%;
}

.sidebar-right-all .SidebarCard {
  width: 300px; /* Card width */
  height: 340px; /* Card height */
  margin: 10px; /* Add margin for spacing */
}

/* course-pagination for course page */
.pagination-container {
  margin-top: auto; /* Pushes pagination to the bottom */
  padding: 10px 0;
}

.course-pagination-container {
  position: sticky; /* Keep the course-pagination fixed at the bottom */
  bottom: 20px; /* Space from the bottom */
  width: 100%; /* Full width of the container */
  text-align: center; /* Center align course-pagination */
  z-index: 10; /* Ensure it's on top of other elements */
}
.course-pagination {
  display: flex;
  justify-content: center; /* Center the course-pagination buttons */
  margin: 30px 0; /* Add margin for spacing */
}

.course-pagination button {
  font-size: 24px;
  width: 50px;
  height: 50px;
  margin: 0 10px; /* Space between buttons */
  padding: 5px; /* Add some padding */
  border: 1px solid #00AEEF; /* Remove default border */
  background-color: #D9D9D9; /* Bootstrap primary color */
  color: #00AEEF; /* Text color */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 50%; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth color transition */
}

.course-pagination .arrow {
  font-size: 24px;
  width: 50px;
  height: 50px;
  margin: 0 10px; /* Space between buttons */
  padding: 5px; /* Add some padding */
  border: none; /* Remove default border */
  background-color: #00AEEF; /* Bootstrap primary color */
  color: #fff; /* Text color */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 50%; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth color transition */
}

.course-pagination button:hover {
  background-color:  #0056b3; /* Darker shade on hover */
}

.course-pagination button:disabled {
  color: #6a6a6a;
  background-color: #D9D9D9; /* Gray background for disabled */
  cursor: not-allowed; /* Not-allowed cursor */
}

.course-pagination .active {
  background-color: #00AEEF; /* Active page button color */
  color: #fff;
}

.course-top {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.course-content {
  display: flex; /* Display sidebar and card container side by side */
}
.course-banner-content{
  display: flex;
  justify-content: center;
  color: #00AAEF;
  font-weight: 600;
  font-size: 36px;
}

.page-item{
margin: 15px;
 color: black; 
 text-align: center;

}
.page-link{
  background-color: #D3D3D3;
  font-weight: 700;
  height: 40px;
  width: 40px;
 border-radius: 50%;
text-decoration: none;
color: black;
}
.page-link:hover{
  background-color: rgba(0, 174, 239, 1);
  color:white;

}

.menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-item {
  width: 100%;
  padding: 15px 20px;
  text-align: left;
  background: none;
  border: none;
  font-weight:600;
  font-size: 20px;
  background-color: white;
  color: black;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: space-between;
}
.sidebar-heading{
  background-color: rgba(0, 174, 239, 1);
  color:rgba(255, 255, 255, 1);
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  padding: 1vh;
}

.menu-item:hover {
  background-color: #00AEEF;
  color:rgba(255, 255, 255, 1);
}
.menu-item.active {
  background-color: #00AEEF;
  color: rgba(255, 255, 255, 1);
}


.submenu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.submenu-item {
  padding: 10px 20px;
  background-color: white;
  color:black;
}

.submenu-item:hover {
  background-color: rgba(0, 174, 239, 1);
  color:rgba(255, 255, 255, 1);
}
.course-banner{
height:200px;
width: 100%;
background-image: url('../../Assets/course_banner.png');
background-repeat: no-repeat;
background-size: cover;
display: flex;
justify-content: center;
align-content: center;
margin-bottom: 2vh;
align-items: center;
}
.sidebar-top{
  display:flex;
  flex-direction: row;
  background-color: #EEEEEE;
}
.course-top{
  display: flex;
  flex-direction: column;
  background-color: #EEEEEE;
}


.sidebar-card-header-div{
position:relative;
display:inline-block; 
height: 150px;
}

.sidebar-card-icon{
position: absolute;
height: 80px;
top: 52px;
left: 50%;
transform: translateX(-50%);
width: auto;
}
.sidebar-card-icon:hover{
height:90px;

}
.sidebar-card-header{
  font-weight: 600;
  font-size: 20px;
  position: absolute;
  left: 45%;
  top:0px;
  transform: translate(-50%,-50%);
  color: white;
  text-align: center;
 }
.sidebar-course-name{
  font-size:22px;
  font-weight:600;
  margin:0px 5px;
  color: #00AEEF;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Limits the text to 2 lines */
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis;
  line-height: 1;
  max-height: 3em;
}
.sidebar-course-details {
text-align: center;
flex-grow: 1; 
padding: 10px;
}
.sidebar-course-time{
  display: flex;
  justify-content: center;
  padding: 10px;
}
.sidebar-course-month{  
font-size: 20px;
font-weight: 400;
margin: 5px 0;
}
.sidebar-course-review{  
font-size: 16px;
font-weight: 600;
font-style: italic;
}
.sidebar-enroll-btn{
  border-radius:10px;
padding:5px 15px;
font-size:20px;
font-weight: 700;
background-color: rgba(0, 174, 239, 1);
color: white;
border: none;
margin-top: 5px;
margin-bottom: 10px;

}
.sidebar-enroll-btn:hover{
background-color: #0D6EFD;
}
.sidebar-card-heading{
color:black;
text-align: center;
font-size: 20px;
font-weight: 700;
padding: 10px;
}

.sidebar-right{
  display: flex;
  flex-direction: column;
  margin-left:10px;
  margin-right: 10px;
 }

.sidebar-right-all {
/* display: flex;
flex-wrap: wrap;
justify-content: flex-start; 
align-items: stretch; 
gap: 20px;  */
margin: 0 auto; 
overflow-y: auto;
max-height: calc(100vh - 100px); 
max-width: 1600px;
padding-left: 10px;
display: grid;
grid-template-columns: repeat(3, 1fr);
gap: 10px;
}

/* .sidebar-card {
  flex: 1 1 calc(25% - 10px);
  box-sizing: border-box;
  max-width: calc(30% - 20px); 
  min-height: 340px;
  min-width: 300px; 
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #fff;
} */

.sidebar-card {
  box-sizing: border-box;
  width: 300px; /* Fixed width for desktop view */
  height: 340px; /* Fixed height for desktop view */
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #fff;
  margin: 10px; /* Margin between cards */
}


.qa-automation{
width:80%;
border-radius: 20px;
background-image: url('../../Assets/qa_background.png');
background-repeat: no-repeat;
background-size: 100%;
/* margin:10px;
padding:10px; */
display: flex;
flex-direction: column;
box-shadow: 5px 5px 5px #D3D3D3;
margin-left:10vw;
margin-right: 10vw;
margin-top: 15px;
margin-bottom: 50px;
}

.qa-inside{
  width:100%;
  display: flex;
  flex-direction: row;
  gap: 60px;
  }

.qa-automation-left{
display: flex;
flex-direction: row;
}

.qa-automation-left img{
margin-right: 80px;
margin-left: 10%;
padding-left: 10px;
height:140px;
}

.video-btn{
border-radius: 10px;
padding: 5px 10px 5px 10px;
border: 1px solid white;
background-color:#00AAEF;
box-shadow:2px 2px 2px white;
color:white;
font-size: 1.5rem;
font-weight: 700;
margin-top: 10px;
margin-left: 10px;
margin-bottom: 30px;
margin-right: 20px;
}

.video-btn:hover{
background-color: #0D6EFD;
}

.video-btn-icon {
  font-size: 1.8rem;
  margin-right: 10px;
  color: #fff;
}

.top-course-name{
  display: flex;
  margin-left: 5%;
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 2.2rem;
  font-weight: 600;
  white-space: nowrap;
  background: linear-gradient( #00AEEF,#006489);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }

  .top-course-name-mob{
    display: none;
  }

.fee{
padding-top: 20px;
font-size: 1rem;
font-weight: 500;
}

.amount{
font-size: 2.2rem;
font-weight: 600;
background: linear-gradient( #00AEEF,#006489);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.strike-price {
  text-decoration: line-through;
  color: #888; /* Gray color to indicate discount */
  margin-left: 10px; /* Space between discounted price and original price */
}

.discount {
  color: orangered;
  font-weight: bold;
  margin-top: 5px;
}

.discount-percent {
  /* font-size: 14px; */
  font-weight: bold;
}

.enroll-message {
  color: green;
  font-weight: bold;
  margin-top: 5px;
}

.enroll-message.error {
  color: red;
}

.qa-automation-middle{
display:flex;
flex-direction: column;
/* margin-top: 6%; */
}

.qa-content{
/* height:150px; */
width: 620px;
font-size:1rem;
font-weight:500;
text-align:justify;
padding-top: 10px;
margin-left: 30px;
padding-left: 20px;
}

.enroll-now{
border-radius: 10px;
border:1px;
background-color: #00AEEF;
color: white;
padding:5px 30px 5px 30px;
font-weight: 700;
font-size: 1.5rem;
box-shadow: 5px 5px 5px #d3d3d3;
}

.fee-enroll-now{
  border-radius: 10px;
  border:1px;
  background-color: #00AEEF;
  color: white;
  padding:5px 30px 5px 30px;
  font-weight: 700;
  font-size: 1.5rem;
  box-shadow: 5px 5px 5px #d3d3d3;
  white-space: nowrap;
  }

.enroll-now:hover{
background-color: #0D6EFD;
border: 2px solid white;
}

.download{
border-radius: 10px;
border: 4px solid #00AEEF;
background-color: #ffffff;
padding:5px 20px 5px 20px;
color:#00AEEF;
font-size:1.5rem;
font-weight: 700;
box-shadow: 5px 5px 5px #D3D3D3;
}

.download:hover{
background-color: #0D6EFD;
color:white;
border: 2px solid white;
}

.qa-button-container{
display: flex;
align-items: center;
justify-content: flex-start;
margin: 10px;
margin-bottom: 20px;
gap: 220px;
}

.qa-button{
  display: flex;
  justify-content: space-between;
  margin: 20px;
  gap: 60px;
  }

.qa-right{
display:flex;
flex-direction:column;
justify-content: space-evenly;
}

.qa-right img{
width:500px;
height:350px;
border-radius: 20px;
}

.certified{
font-weight: 700;
font-size:24px;
color: white;
text-align: right;
}

.mob-cert{
  display: none;
  }

.mentoring-mode-content{
font-size: 20px;
font-weight: 400;
}
.mentoring-mode-heading{
  font-size: 24px;
  font-weight: 400;
  }

.modal-overlay {
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: rgba(0, 0, 0, 0.5); /* Darken background */
display: flex;
justify-content: center;
align-items: center;
z-index: 1000; /* Ensure it overlays other content */
}

.modal-content {
background-color: white;
overflow: hidden;
border-radius: 20px;
width: 600px; /* Adjust width as needed */
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
position: relative;
}

.modal-request {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
border-radius: 20px;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
align-items: center;
justify-content: center;
z-index: 1000;
}

.modal-request-content {
background-color: white;
border-radius: 20px;
width: 600px; /* Adjust width as needed */
position: relative;
}

.batch-text{
  color: #000;
  font-weight: 400;
  display: flex;
  align-items: center;
  font-size: 16px;
  justify-content: center;
  padding: 20px;
}

.button-close {
position: absolute;
top: 10px;
right: 10px;
font-size: 2.2rem;
font-weight: 700;
color: white;
cursor: pointer;
background: #006489;
border-radius: 50%;
}

.btn.btn-outline-secondary {
  height: 30px;
  border-radius: 5px 0px 0px 5px;
  margin-bottom: 5px;
  }

/* Style for request batch modal */
.request-batch {
padding-bottom: 20px;
border-radius: 20px !important;
background-color: white;
}

.request-header {
height: 100px;
border-radius: 20px 20px 0px 0px;
background: linear-gradient(to right, #006489, #00AEEF, #006489);
color: white;
font-size: 24px;
font-weight: 600;
text-align: center;
display: flex;
align-items: center;
justify-content: center; 
}

/* Align the calendar icon at the end */
.date-picker-wrapper {
position: relative;
display: flex;

}

.black-calendar {
position: absolute;
right: 10px;
top: 50%;
transform: translateY(-50%);
cursor: pointer;
}

/* Add gradient background to date picker input */
.date-picker, .time-picker {
background: linear-gradient(45deg, #00AEEF, #006489);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
color: white;
right: 10px;
top: 50%;
border: none;
border-radius: 5px;
}


/* Time input adjustments */
/* .time-picker {
background: linear-gradient(45deg, #00AEEF, #006489);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
color: white;
border: none;
padding-left: 10px;
} */

/* Input field styling */


.btn-submit {

width: 520px !important;
background-color: #00AEEF;
}

.key-highlights{
margin-bottom: 5vh;
padding:3vh;
width:80%;
margin-left: 10vw;
margin-right: 10vw;
background-color: #FFFFFF;
box-shadow: 5px 5px 5px #D3D3D3;
border-radius:10px;
margin-top: 5vh;
}

.key-highlights-content{
width:8.5vw;
display: flex;
flex-direction: column;
justify-items: center;
cursor:pointer;
}

.key-highlights-para{
font-size: 1rem;
font-weight: 600;
text-align:center;
}

.key-icons{
height:60px;
width:60px;
margin-left: 30px;
background: linear-gradient(to right, #00AEEF,#006489);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.key-highlights-header{
display: flex;
justify-content: space-evenly;
flex-wrap: wrap;
}

/* Add smooth scrolling behavior to the entire page */
html {
scroll-behavior: smooth;
}

.upcoming-header {
  margin-top: 2vh;
  margin-bottom: 5vh;
  background: linear-gradient(to right, #006489, #00AEEF, #006489);
  box-shadow: 5px 5px 5px hsl(0, 0%, 83%);
  z-index: 1000;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 1000;
  animation: slide-down 0.3s ease-in-out;
}

@keyframes slide-down {
  from {
    transform: translateY(-10%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.upcoming-header-content {
font-size: 1.1rem;
font-weight: 400;
color: white;
display: flex;
flex-direction: row;
justify-content: space-evenly;
padding: 25px;
}

.upcoming-link {
text-decoration: none;
transition: text-decoration 0.3s ease;
cursor: pointer;
display: inline-block;
color: white !important;
position: relative;
}

.upcoming-link:hover::after {
content: '';
position: absolute;
left: -4px;
right: -4px;
bottom: -16px;
height: 4px;
background-color: white;
}

.upcoming-batch { 
width: 80%;
margin: 2vh;
margin-left: 10vw;
margin-right:10vw;
align-items: center;
border-radius: 10px;
background-color: #FFFFFF;
box-shadow: 5px 5px 5px #d3d3d3;
padding: 2vh;
margin-bottom: 5vh;  
}

.batch-type{ 
width:95%;
margin-left: 5vh;
padding: 1vh;
border-radius: 10px 10px 0px 0px;
background: linear-gradient(to right,#006489,#00AEEF,#006489);
display: flex;
justify-content: space-between;
color:white;
font-size: 1.25rem;
font-weight: 400;
text-align: center;
}

.batch-type p{
text-decoration: none;
transition: text-decoration 0.3s ease;
cursor: pointer;
display: inline-block;
position: relative;
}

.batch-type p:hover::after{
content: '';
font-weight: 600 important;
position: absolute;
left: -4px;
right: -4px;
bottom: -4px;
height: 4px;
background-color: white;
}

.batch-type-content {
cursor: pointer;
padding: 1vh;
position: relative;
}

.batch-type-content.active::after {
content: '';
position: absolute;
bottom: -4px;
left: 0;
width: 100%;
height: 4px;
background-color: white;
transition: width 0.3s ease-in-out;
}

.batch-type-content:hover {
color: white;
transition: background-color 0.3s ease;
}

.batch-type-content.active:hover::after {
width: 100%;
}

.profile-building{
display: flex;
flex-direction: column;
padding-left: 10vh;
padding-right: 10vh;
}

.profile-building-div img{
height: 50px;
width:50px;
margin-right: 50px;
}

.profile-building-div h5{
font-weight: 700;
font-size: 20px;
}

.profile-building-div p{
font-size: 18px;
}

.profile-building-div{
display: flex;
flex-direction: row;
padding-top: 4vh;
}

.batch-schedule {
  
  display: flex;
 margin-left: 5px;
  width: 99%;
  height: auto;
  border-radius: 10px;
padding-top: 20px;
padding-left: 5px;
padding-right: 5px;
}

.left {
flex: 2;
background-color: white;
margin: 5px;

}
.left-mode{
  flex: 2;
background-color: white;
  padding: 20px;
}

.right {
flex: 1;
background-color: white;
padding: 20px;
justify-content: space-evenly;
align-items: center;
text-align: center;
box-sizing: border-box;
border: 2px solid #d3d3d3;
border-radius: 10px;
}

.separator {
/* width: 2px; */
background-color: #d3d3d3;
margin: 5px;
}

.partition {
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 10px;
box-sizing: border-box;
border: 2px solid #d3d3d3;
margin-bottom: 10px;
border-radius: 10px;
width: 100%;
}

.partition label {
display: flex;
align-items: center;
width: 100%;
}


.partition-schedule {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width: 95%;
  flex-wrap: nowrap;
  text-align: center;
  padding: 10px;
}

/* .custom-radio {
width: 25px;
height: 25px;
border-radius: 50%;
background-color: white;
border: 2px solid grey;
margin-right: 15px;
position: relative;
cursor: pointer;
}

.custom-radio::after {
content: '';
width: 14px;
height: 14px;
background: transparent; 
border-radius: 50%;
position: absolute;
top: 4px;
left: 4px;
display: none;
}

input[type="radio"]:checked + .custom-radio::after {
display: block; 
background: linear-gradient(to right, #006489, #00AEEF, #006489); 
}

input[type="radio"] {
display: none; 
} */

.hidden-radio {
  display: none; /* Hide the default radio button */
}

.radio-label {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: 1;
}

.custom-radio {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #006489; /* Outer circle border */
  margin-right: 10px;
  position: relative;
  flex-shrink: 0;
}

.custom-radio::after {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: transparent; /* Hidden initially */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: background 0.3s ease;
}

.hidden-radio:checked + .custom-radio::after {
  background: linear-gradient(#00AEEF, #006489); /* Inner circle gradient when checked */
}

.batch-date {
font-size: 20px;
font-weight: 600;
}

.batch-date-fee {
  font-size: 20px;
  font-weight: 400;
  }

.date-span {
font-size: 16px;
font-weight: 300;
color: #F4111F;
}

.demo-icon {
  color: #FF5C01;
  margin-right: 1vh;
  font-size: 8px;
  animation: growShrink 2s infinite ease-in-out;
  }

.demo {
color: #FF5C01;
font-size: 20px;
font-weight: 300;
font-style: italic;
display: flex;
align-items: center;
white-space: nowrap;
}

.class-icon {
  color: rgba(16, 201, 0, 1);
  margin-right: 1vh;
  font-size: 8px;
  animation: growShrink 2s infinite ease-in-out;
  }

.class {
color: rgba(16, 201, 0, 1);
font-size: 20px;
font-weight: 300;
font-style: italic;
display: flex;
align-items: center;
white-space: nowrap;
}

.free
{
  font-size: 3rem;
  font-weight: 600;
  background: linear-gradient( #00AEEF,#006489);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.corporate {
  position: relative;
  width: 100%;
}

.corporate-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.corporate-image {
  width: 80%; /* Make the image take full width */
  height: auto; /* Adjust height automatically */
  object-fit: cover; /* Ensure the image covers the container without stretching */
  margin-left: 10vw;
  margin-right: 10vw;
}

.know-more {
  position: absolute;
  right: 13%; /* Position button towards the right of the image */
  bottom: 15%; /* Position button slightly above the bottom */
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.438);
  color: black;
  font-size: 1.5rem;
  font-weight: 700;
  border: none;
  transition: all 0.3s ease;
}

.know-more:hover {
  background-color: #000;
  border: 2px solid white;
  box-shadow: 2px 2px 2px white;
  color: white;
}

.qa-course-heading img{
  padding: 10px;
  max-height: 600px;
  display: flex;
  justify-content: center;

}

.schedule{
  text-align:center;
  font-size:20px;
  margin-top: 10px;
}

.schedule-span{
  font-size: 20px;
  font-weight: 600;
  background: linear-gradient( #00AEEF,#006489);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
cursor:pointer;
margin-left: 15px;
}

.qa-course{
  background-color: white;
  margin: 2vh;
  margin-left: 10vw;
  margin-right: 10vw;
  margin-top: 5vh;
  margin-bottom: 10vh;
  padding:4vh;
  border-radius: 10px;
  box-shadow: 5px 5px 5px #d3d3d3;
}

.qa-heading{
  font-size: 2rem;
  font-weight: 700;
  background: linear-gradient( #00AEEF,#006489);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
padding-bottom: 1.5vh;
}

.qa-subheading{
  font-size: 1.2rem;
  font-weight:600;
  padding-bottom: 2vh;
  color:#006489;
}

.qa-sub-content{
  font-size: 16px;
  font-weight: 400;
}

.curriculum {
  margin-top: 10vh;
  margin-bottom: 10vh;
  background-color: #FFFFFF;
  margin: 2vh;
  margin-left: 10vw;
  margin-right: 10vw;
  box-shadow: 5px 5px 5px #d3d3d3;
  border-radius: 10px;
  padding: 10px 35px;
  transition: height 0.5s ease;
}

.curriculum-expanded {
  height: auto; 
}

.curriculum-content {
  border-radius: 10px;
  display: flex;
  justify-content: space-between;    
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
align-content: center;
text-align: left;
  background-color: #EEEEEE;
  font-size: 1.2rem;
  font-weight: 600;
  color: #006489;
  cursor: pointer;

}

.topic-details {
  background-color: #Fff;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 1vh;
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  display: flex;
  justify-content: space-between;
}

.bullet-list {
  margin: 0;
  padding-left: 20px;
}

.bullet-list li {
  margin-bottom: 5px;
  list-style-type: disc;
}

.btn-pdf-icon{
  font-size: 30px;
  color: red;
  }

.btn-curriculum{
  border-radius: 10px;
  padding:5px 10px 5px 10px;
  box-shadow: 5px 5px 10px #d3d3d3;
  background-color: #FFFFFF;
  color: #006489;
  font-size: 1rem;
  font-weight: 600;
  border:none;
}
.curriculum-head{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
}

.curriculum-topic{
  display:flex;
  justify-content:space-evenly;
  flex-direction: column;
  gap: 10px;
}

.view-more-btn{
  height: 37px;
  width: 146px;
  border-radius: 10px;
  padding: 5px 20px 5px 20px;
  background-color: #00AEEF;
  border:none;
  box-shadow: 5px 5px 5px #D3D3D3;
  color: white;
  font-weight: 700;
  font-size: 20px;
}

.view-more-btn:hover{
  background-color: #0D6EFD;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.15);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.play-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 3px 0;
  /* animation: pulse 1.5s infinite; */
}

.play-icon-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  cursor: pointer;
  animation: pulse 1.5s infinite;
}

.view-div{
  display:flex;
  justify-content:center;
  margin-top: 10px;
}

.mode-of-training{
 margin-bottom: 5vh !important;
 margin-top: 10vh !important;
  background-color: white;
  margin: 2vh;
  margin-left: 10vw;
  margin-right: 10vw;
  border-radius: 10px;
  padding: 18px 43px 20px 43px;
  box-shadow: 5px 5px 5px #D3D3D3;
}

.mode-of-training-head{
  height: 600px;
}

.live-mode{
  padding-top: 2vh;
  padding-left: 7vh;
  font-size: 16px;
  font-weight: 400;
  color:black;
  padding-right: 4vh;
}

.feature-icons{
display: flex;
flex-direction: row;
gap: 2vh;
}

.live-head{
font-size:1.2rem;
font-weight:600;
padding-left: 7vh;
padding-right: 4vh;
margin-bottom: 0;
}

.feature-content{
font-weight: 400;
font-size: 1rem;
padding-top: 2vh;
cursor: pointer;
}

.features-icon{
padding-top: 10px;
width: auto;
height:45px;
}

.live-mode-feature{
display: flex;
flex-direction: row;
justify-content: space-evenly;
}

.career-support{
margin-bottom: 5vh;
margin-top: 10vh;
margin-left:10vw;
margin-right: 10vw;
border-radius: 10px;
box-shadow: 5px 5px 5px #d3d3d3;
background-color:white;
}

.career-support-head{
background: linear-gradient( #00AEEF,#006489);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding:1vh;
  padding-left:6.5vh;
  font-size:2.2rem;
  font-weight:600;
}

.career-support-content{
display: flex;
justify-content: space-around;
flex-direction: row;
padding-left: 6vh;
padding-top: 2vh;
gap:2vh;
}

.career-support-content img{
height:4vh;
width: 2vw;
}

.career-support-content-header{
font-size: 1.25rem;
font-weight: 400;
}

.career-support-content-para{
font-size: 1rem;
font-weight: 600;
}

.sticky-bar {
font-size: 14px;
position: fixed;
bottom: 0;
width: 100vw;
height: 50px;
background-color: #000000; 
color: white;
display: flex;
align-items: center;
justify-content: space-between;
padding: 0 10px;
z-index: 1000;
padding-right: 25px;
}

.contact-info {
display: flex;
align-items: center;
gap:10px;
}

.contact-item {
margin-right: 20px; 
font-size: 16px;
}

.contact-button {
background-color: #fff; 
color: black;
border: none;
margin:10px;
padding: 5px 10px;
border-radius: 5px;
font-size: 16px;
font-weight: 700;
cursor: pointer;
transition: background-color 0.3s ease;
}

.contact-button:hover {
background-color: black;
color:white;
font-weight: bold; 
border:2px solid white;
}
.blogs-header{
  margin-left: 10vw;
}
.batch-content-background{
  background-color: #EEEEEE;
  margin-left: 40px;
  margin-right: 25px;
}

@media (min-width: 760px) and (max-width: 1024px){
  .upcoming-batch{
    width: 95%;
    margin-left: 20px;
    padding-left: 20px;
  }
  .batch-type{
    margin-left: 5px;
  }
  .separator{
    margin: 1px;
  }
  .batch-schedule{
    width: 95%;
    margin-left: 20px;
  }
  .qa-course-heading img{
    height: 300px;
    width: auto;
    padding: 20px;
  }
  
  .key-highlights{
    width: 95%;
    margin-left: 20px;
  }
  .key-icons{
    height: 45px;
    width: 45px;
    margin-left: 10px;
  }
  .key-highlights-para{
    font-size: 12px;
  }
  
  .qa-right img{
    height: 150px !important;
    width: 260px !important;
    margin-top: -15px;
  }
  .qa-automation-left img{
    height: 80px;
  }

  .qa-button{
    margin: 5px;
  }
  .qa-automation{
    width: 98% !important;
    margin-left: 10px;
    height: 345px;
    padding: 0px;
    justify-content: space-evenly;
  }
  .qa-right{
    margin-right: 0px;
  }
  .video-btn{
    margin-top: 2px;
    font-size: 16px;
    margin-left: 10px;
    margin-top: 20px;
  }
  .certified{
    font-size: 16px;
  }
  .blogs-header{
    margin-left: 2vw;
  }
  .fee{
    padding-top: 5px;
  }

  .qa-content{
    width: 400px !important;
    margin-left: 0px;
    padding-top: 0px;
    font-size: 14px;
  }
 
  .qa-automation-left{
    margin-left: 0px;
  }
  .mode-of-training{
    width: 95%;
    margin-left: 20px;
  }
  .qa-right{
    margin-right: 0px;
  }

  .batch-content-background{
    margin-left: 5px;
    margin-right: 22px;
  }
  .certified{
    font-size: 16px;
  }
  .blogs-header{
    margin-left: 2vw;
  }
  .fee{
    padding-top: 5px;
  }
  .enroll-now,.download{
    margin: 5px;
    font-size: 18px;
    padding: 5px 10px;
  }
  .qa-content{
    width: 400px !important;
    margin-left: 0px;
    padding-top: 0px;
    font-size: 14px;
  }

  .qa-automation-left{
    margin-left: 0px;
  }
  .mode-of-training{
    width: 95%;
    margin-left: 20px;
  }
  .qa-right{
    margin-right: 0px;
  }
  .video-btn{
    margin-top: 2px;
    font-size: 16px;
    margin-left: 10px;
   
  }
  .certified{
    font-size: 16px;
  }
  .blogs-header{
    margin-left: 2vw;
  }
  .fee{
    padding-top: 5px;
  }

  .qa-content{
    width: 400px !important;
    margin-left: 0px;
    padding-top: 0px;
    padding-left: 20px;
    font-size: 14px;
  }
  .qa-automation{
    height: 345px;
    padding: 0px;
    justify-content: space-evenly;
  }
  .qa-automation-left{
    margin-left: 0px;
  }

  .top-course-name{
    font-size: 1.5rem;
  }

  .amount{
    font-size: 1.5rem;
  }
  .mode-of-training{
    width: 95%;
    margin-left: 20px;
  }
  .career-support{
    width: 95%;
    margin-left: 20px;
  }
}
  
@media (min-width:1025px) and (max-width: 1366px) {
  .sidebar-card {
    width: 280px;
    height: 320px;
  }
  .sidebar-right{
    margin-right: 10px;
  }
  .sidebar{
    width: 350px;
    min-width: 320px;
  }
  .sidebar-right-all{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-height: calc(65vh - 100px); 
    gap: 5px;
  }
}
  
@media screen and (max-width: 1024px){
  .contact-info{
     gap: 10px;
  }
  .know-more{
    font-size: 16px;
    padding: 5px;
  }
 }
  
@media (min-width: 481px) and (max-width: 768px){
  .know-more{
    font-size: 16px;
    padding: 5px;
  }
  .features-icon{
    width: 30px !important;
    height: 22px !important;
    padding: 0px;
  }
  .career-support{
    margin-left: 10px;
  }
  .profile-building-div img{
    height: 30px;
    width: 42px;
  }

  .contact-info img,        /* WhatsApp icon */
.contact-info .whatsapp-number-footer,  /* WhatsApp number */
.contact-info .training-email-footer,   /* Email text */
.contact-info svg {       /* Icons */
  display: none;          /* Hide in mobile view */
}

.query-content { 
  font-size: 16px;        /* Adjust text size if needed */
  margin-right: 5px;        /* Optional: adjust margins */
}

.contact-button {
  font-size: 20px;        /* Adjust button size if needed */
  margin:5px;
}

.sidebar-card {
  width: 300px;
  height: 320px;
}

.sidebar{
  margin-left: 5px;
  width: auto;
  min-width: 250px;
  margin-bottom: 30px;
  margin-top: 20px;
  overflow-y: scroll;
}

.sidebar-right-all{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  max-height: calc(100vh - 50px); 
  gap: 5px;
}

.sidebar-course-name{
  font-size:20px;
}
}
@media (min-width:1024px) and (max-width: 1366px){
.qa-automation{
  width: 85%;
  margin-left: 80px;
}
.qa-automation-left img{
  height: 110px;
}

.batch-type{
  margin-left: 5px;
}
.separator{
  margin: 1px;
}
.batch-schedule{
  width: 95%;
  margin-left: 20px;
}
.qa-course-heading img{
  height: 400px;
  width: auto;
  padding: 30px;
}

.key-highlights{
  width: 85%;
  margin-left: 80px;
}
.key-highlights-para{
  font-size: 12px;
}

.qa-right img{
  height: 220px !important;
  width: 300px !important;
 
}
.qa-button{
  margin: 5px;
}

.video-btn{
  font-size: 16px;
  margin-left: 40px;
  margin-top: 10px;
}
.certified{
  font-size: 16px;
}
.blogs-header{
  margin-left: 2vw;
}
.fee{
  padding-top: 5px;
}


.qa-automation-left{
  margin-left: 0px;
}
.qa-right{
  margin-right: 0px;
}

.batch-content-background{
  margin-left: 5px;
  margin-right: 35px;
}

.enroll-now,.download{
  margin: 5px;
  font-size: 18px;
  padding: 5px 10px;
}
.qa-content{
  width: 450px !important;
  margin-left: 0px;
  padding-top: 0px;
  padding-left: 20px;
  font-size: 16px;
}

.qa-automation-left{
  margin-left: 0px;
}

.qa-right{
  margin-right: 0px;
  margin-top: 10px;
}

.certified{
  font-size: 16px;
}
.blogs-header{
  margin-left: 2vw;
}
.fee{
  padding-top: 5px;
}
.qa-automation{
  height: 375px;
  padding: 0px;
  justify-content: space-evenly;
}

.qa-automation-left{
  margin-left: 0px;
}
.amount{
  font-size: 1.5rem;
}
.mode-of-training{
  width: 85% !important;
  margin-left: 80px;
}
.career-support{
  width: 85%;
  margin-left: 80px;
}
.query-content, .whatsapp-number-footer, .training-email-footer { 
  font-size: 16px;        /* Adjust text size if needed */
}

}
@media (min-width:769px) and (max-width: 1024px) {
  .sidebar-card {
    width: 290px;
    height: 300px;
  }
  .sidebar{
    margin-left: 5px;
    width: 280px;
    min-width: 250px;
    margin-bottom: 30px;
    margin-top: 20px;
    overflow-y: visible;
  }
  .sidebar-right-all{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 5px;
  }
  .course-banner{
    height: 220px;
  }
  .course-banner-content{
   text-align: center;
  margin-top: -40px;
  }
}  
 

@media (min-width:350px) and (max-width:480px){
  .curriculum {
    width: 90%;
    margin: 5px;
    margin-bottom: 20px;
    margin-left: 5%;
    margin-right: 5%;
    padding: 20px;
  }

  .btn-pdf-icon{
  font-size: 12px;
  }

.btn-curriculum{
  padding: 2px;
  font-size: 12px;
  border-radius: 5px;
  margin-bottom: 10px;
  white-space: nowrap;
}
.mode-of-training{
  width: 90%;
  margin: 5px;
  margin-left: 5%;
  margin-right: 5%;
  padding: 10px;
  margin-bottom: 20px;
}            
.live-mode{
  padding: 10px;
  font-size: 10px;
  text-align: justify;
  margin-bottom: 0;
}

.feature-content{
  font-size: 10px;
  padding: 5px 5px 5px 0;
  white-space: nowrap;
  margin-bottom: 0;
}
.career-support{
  width: 90%;
  margin: 5px;
  margin-left: 5%;
  margin-right: 5%;
  padding: 10px;
  margin-bottom: 20px;
}
.career-support-head{
  font-size: 16px !important;
  padding-bottom: 5px;
  padding-left: 0;
  margin-bottom: 10px;
}
.profile-building{
  padding-left: 10px;
  padding-right: 10px;
}

.profile-building-div{
  padding-top: 10px;
  }

.profile-building-div h5{
  font-size: 12px;
}
.profile-building-div p{
  font-size: 10px;
  text-align: justify;
}
.profile-building-div img{
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.qa-automation {
  width: 90%;
  background-color: white; /* Replace background image with white */
  flex-direction: column; /* Change flex direction to column */
  margin-left: 5%;
  margin-right: 5%;
  background-image: none;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 5px;
}

.qa-inside{
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  }

.qa-automation-left {
  flex-direction: row; /* Make the content stack vertically */
  margin-left: 0;
  justify-content: space-around !important;
}

.qa-automation-left img {
  /* margin-right: -48px; */
  height: 80px;
  margin-left: 5px;
  padding-left: 5px;
  margin-top: 5px;
}

.qa-automation-middle {
  margin-top: 2%;
}

.qa-content {
  width: 100%;
  text-align: justify;
  margin-left: 0;
  padding: 5px;
  margin-bottom: 0;
  font-size: 10px;
}

.qa-content p{
  padding: 5px;
  margin-bottom: 0;
}

.qa-button-container{
  display: flex;
  width: 95%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  }

.qa-button {
  flex-direction: column;
  width: 95%;
  justify-content: center;
  align-items: center;
  margin: 5px;
  margin-top: 0;
  gap: 5px;
}

.enroll-now,
.download {
  width: 100%;
  height: 40px;
  margin: 10px;
  padding: 5px;
  font-size: 14px;
  border-radius: 5px;
}

.qa-right{
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  }

.qa-right img {
  width: 310px;
  height: auto; /* Ensure image remains responsive */
  border-radius: 10px;
  margin-left: 0px;
}

.mob-cert {
  display: flex;
  font-size: 10px;
  justify-content: flex-end;
  font-weight: 700;
  color: #000;
  margin: 0 10px 0 0;
}

.certified{
 display: none;
  }
.fee{
padding-top: 5px;
font-size: 10px;
}
.video-btn {
  width: 100%;
  height: 35px;
  margin: 10px;
  padding: 5px;
  font-size: 12px;
  border-radius: 5px;
}

.video-btn-icon {
  font-size: 24px;
}

.course-content {
  display: block;
}

.mob-sidebar-heading{
  background-color: rgba(0, 174, 239, 1);
  color:rgba(255, 255, 255, 1);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 50px;
  width: auto;
  margin: 0 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.mob-sidebar-text{
  color: #000;
  font-weight: 400;
  display: flex;
  align-items: center;
  font-size: 12px;
  width: auto;
}

.menu-item {
  padding: 10px 20px;
  font-size: 14px;
  width: 100%;
}

.submenu-item {
  padding: 3px 0;
}

/* .sidebar {
  min-width: 100%;
  max-width: 380px;
  width: 100%;
  height: auto;
  padding-bottom: 30px;
  margin-bottom: 30px;
} */

.mobile-dropdown {
  width: auto;
  margin: 10px;
}

.dropdown-select {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border-color: #00AEEF;
}

.sidebar-top{
  display: block;
}

.sidebar.show-cards .sidebar-right {
  display: none; /* Show cards when a menu is clicked */
  /* margin-top: 10px; */
}

.sidebar-right-container {
  display: block;
  width: auto;
  max-width: 100%;
  padding: 0 10px;
}

.sidebar-right{
  max-width: 100%;
  width: 380px;
  margin-top: 10px; /* Add some space between sidebar and cards */
  display: block; /* Hide cards by default */
 }

.sidebar-enroll-btn {
  display: none;
}

.sidebar-card {
  flex-direction: row;
  max-Width: 380px;
  width: 100%;
  min-width: 100%;
  max-height: 125px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  align-items: flex-start;
  margin: 5px;
}

.sidebar-card-header-div {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  display: flex; */
  flex-direction: row;
  align-items: center;
  width: 150px;
  height: 125px;
  margin-bottom: 0;
}

.sidebar-card-heading{
  font-size: 14px;
  padding: 5px;
  white-space: nowrap;
  }

  .sidebar-card-icon{
    padding: 10px;
    height: 70px;
    width: auto;
    top: 30px;
   }

.sidebar-course-details {
  padding: 10px;
  width: 220px;
  max-height: 125px;
  padding-left: 0;
}
.blogs-header{
  margin-left: 20px;
}

.sidebar-course-name{
  font-size: 14px;
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 2; /* Limits the text to 2 lines */
overflow: hidden; /* Hides overflowing text */
text-overflow: ellipsis;
line-height: 1.5;
max-height: 3em;
}

.sidebar-course-time{
  gap: 5px;
  font-size: 10px;
}
.sidebar-course-month{  
  font-size: 12px;
  white-space: nowrap;
}
.sidebar-course-review{  
  font-size: 10px;
  font-weight: 600;
  font-style: italic;
  white-space: nowrap;
}

/* Limit the number of visible cards on mobile */
.sidebar-right-all {
  /* overflow: hidden;
  display: flex;
  flex-direction: row; */
  /* max-height: 350px; */
  /* position: relative; */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  overflow-y:visible;
  scroll-behavior: smooth;
  margin-top: 10px;
  max-width: 100%;
  min-width: 100%;
  width: auto;
  padding-bottom: 10px;
  gap: 0px;
  padding-left: 5px;
  padding-right: 5px;
  height: auto;
}

/* course-pagination for course page */
.course-pagination-container {
  position: sticky; /* Keep the course-pagination fixed at the bottom */
  bottom: 10px; /* Space from the bottom */
  width: 100%; /* Full width of the container */
  text-align: center; /* Center align course-pagination */
  z-index: 10; /* Ensure it's on top of other elements */
}
.course-pagination {
  display: flex;
  justify-content: center; /* Center the course-pagination buttons */
  margin: 10px 0; /* Add margin for spacing */
}

.course-pagination button {
  font-size: 16px;
  width: 30px;
  height: 30px;
  margin: 0 10px; /* Space between buttons */
}

.course-pagination .arrow {
  font-size: 16px;
  width: 30px;
  height: 30px;
  margin: 0 10px; /* Space between buttons */
}

.course-banner{
  height: 100px;
}

.course-banner-content{
  color: white;
  font-weight: 600;
  font-size: 16px;
}

.key-highlights {
  width: 90%;
  padding: 10px;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 20px;
}

.key-highlights-header {
  justify-content: center;
}

.key-highlights-content {
  width: 30%; 
  gap: 5px;
  margin-bottom: 10px; 
}

.key-icons {
  margin-left: 0; 
  align-self: center; 
  height: 30px;
  width: 30px;
}

.key-highlights-para {
  font-size: 12px;
}

.know-more{
  font-size: 12px;
  right: 2%; 
  border-radius: 10px;
  padding: 1px 5px;
  border-radius: 5px;
}

.upcoming-header-content {
  justify-content: flex-start; /* Align items to the left */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent line breaks */
  padding: 15px;
  font-size: 12px;
}

.upcoming-link {
  margin-right: 20px; /* Spacing between each category link */
}

.upcoming-header {
  padding-left: 0px; /* Padding to ensure some space at the start */
  margin-top: 10px;
  margin-bottom: 20px;
}

.top-course-name-mob{
  display: flex;
  margin-left: 2%;
  margin-bottom: 5px;
  margin-top: 0px;
  font-size: 16px;
  white-space: nowrap;
  font-weight: 600;
  background: linear-gradient( #00AEEF,#006489);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.top-course-name{
  display: none;
}

.top-course-data-mob{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.amount{
  font-size: 16px;
}

.batch-type {
  justify-content: flex-start; /* Align items to the left */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent line breaks */
  padding: 15px;
  font-size: 12px;
  width: 100%;
  margin-left: 0px;
}

.batch-type-content {
  padding: 0px;
  padding-bottom: 5px;
  margin-bottom: 0;
  margin-right: 15px;
  margin-left: 5px;
  }
  
.batch-schedule {
  flex-direction: column; 
  width: 95%;
  margin: 5px;
  padding-top: 5px;
}

.batch-content-background{
  margin-left: 5px;
  margin-right: 5px;
}

.modal-request-content {
  background-color: white;
  border-radius: 20px;
  width: 350px; /* Adjust width as needed */
  position: relative;
  }

.left, .right {
  flex: 1; 
  width: 100%; 
  padding: 0px; 
}

.partition-schedule {
  flex-direction: row; 
  gap: 10px; 
  margin-top: 5px;
}

/* .custom-radio {
  width: 20px; 
  height: 20px;
  margin-right: 10px; 
} */

.custom-radio {
  width: 16px;
  height: 16px;
  border: 1px solid #006489; /* Outer circle border */
}

.custom-radio::after {
  width: 8px;
  height: 8px;
}

.batch-date {
  font-size: 12px; 
}
.qa-heading{
  font-size: 16px !important;
  padding-bottom: 5px;
  margin-bottom: 10px;
  /* white-space: nowrap; */
}

.upcoming-batch { 
  width: 90%;
  margin: 5px;
  margin-left: 5%;
  margin-right:5%;
  padding: 10px;
  margin-bottom: 20px;
  }

.date-span {
  font-size: 10px; 
}

.demo, .class {
  font-size: 12px; 
  white-space: nowrap;
}

.free {
  font-size: 20px; 
}

.corporate-content {
  margin-top: 20px;
  margin-bottom: 20px;
}

.corporate-image {
  width: 100%; /* Make the image take full width */
}

.fee-enroll-now {
  width: 100%;
  height: 40px;
  margin: 0px;
  padding: 5px;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.schedule img{
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.schedule{
  font-size:10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.schedule-span{
  font-size: 12px;
  margin-left: 5px;
}

.left-mode{
  padding: 10px;
}

.mentoring-mode-content{
  font-size: 10px;
  font-weight: 400;
  text-align: justify;
  margin-bottom: 10px;
  }
  .mentoring-mode-heading{
    font-size: 12px;
    font-weight: 600;
    }

    .qa-course{
      margin: 0px;
      margin-left: 5%;
      margin-right: 5%;
      padding:20px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .qa-subheading{
      font-size: 12px;
      padding-bottom: 5px;
      margin-bottom: 3px;
    }
    
    .qa-sub-content{
      font-size: 10px;
      text-align: justify;
    }

    /* Increase toolbar font size */
.ql-toolbar {
  font-size: 16px; /* Increase font size in toolbar */
}

/* Increase editor height */
.ql-container {
  height: 300px !important; /* Adjust height */
}

/* Increase toolbar button size */
.ql-toolbar button {
  font-size: 14px; /* Make toolbar buttons bigger */
  padding: 5px 10px;
}

    
    .curriculum-expanded {
      height: auto; 
    }
    
    .curriculum-content {
      border-radius: 5px;
      padding: 10px; 
      margin-bottom: 0; 
      font-size: 12px; 
      text-align: left;   
    }

    .curriculum-content p{
      margin-bottom: 0;
    }
    
    .topic-details {
      padding: 10px;
      border-radius: 5px;
      margin-bottom: 5px;
      font-size: 10px;
      text-align: justify;
    }
    
    .bullet-list {
      margin: 0;
      padding-left: 20px;
    }
    
    .bullet-list li {
      margin-bottom: 5px;
      list-style-type: disc;
    }
    
    .curriculum-topic{
      gap: 10px;
    }
    
    .view-more-btn{
      height: auto;
      width: auto;
      border-radius: 5px;
      padding: 3px 10px;
      font-size: 12px;
    }

    .mode-of-training-head{
      height: 600px;
    }
    
    .features-icon {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 32px;
    height: auto;
    padding-left: 10px;
    padding-top: 5px;
    }
    
    .live-head{
    font-size:12px;
    padding: 0 10px;
    margin-bottom: 0px;
    }
    
    .live-mode-feature{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    }
    
    .career-support-content{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    padding-left: 6vh;
    padding-top: 2vh;
    gap:2vh;
    }
    
    .career-support-content img{
    height:4vh;
    width: 2vw;
    }
    
    .career-support-content-header{
    font-size: 1.25rem;
    font-weight: 400;
    }
    
    .career-support-content-para{
    font-size: 1rem;
    font-weight: 600;
    }
    
    .contact-info {
    display: flex;
    align-items: center;
    gap:1.5vw;
    }
    
    .contact-item {
    margin-right: 20px; 
    font-size: 16px;
    }
    .blogs-header{
      margin-left: 10vw;
    }

.qa-course-heading img{
  height: auto;
  width: 300px;
  padding: 10px;
  margin-bottom: 10px;
}

.sticky-bar {
  height: 40px;
}

.contact-info img,        /* WhatsApp icon */
.contact-info .whatsapp-number-footer,  /* WhatsApp number */
.contact-info .training-email-footer,   /* Email text */
.contact-info svg {       /* Icons */
  display: none;          /* Hide in mobile view */
}

.query-content { 
  font-size: 12px;
}

.contact-button {
  font-size: 12px;        /* Adjust button size if needed */
  margin:5px;
}

 /* Have any query popup */
 .modal-content {
  width: 350px !important; /* Adjust width for mobile view */
  height: auto;
}

.request-batch {
  width: 350px; /* Adjust width for mobile view */
  height: auto; /* Allow height to adjust automatically */
}

.request-header {
  font-size: 16px; /* Adjust header font size */
  height: 50px; /* Adjust height for header */
}

.form-label {
  font-size: 12px; /* Set label font size to 12px */
  margin: 0 1vh; /* Adjust margin to fit smaller screen */
}

.form-control-query {
  width: 320px; /* Set input field width to 330px */
  height: 35px; /* Set input field height to 40px */
  margin: 10px; /* Center input fields and adjust margin */
}

.mobile-number-container {
  margin-left: 10px; /* Center align mobile number field */
}

.mobile-number-container input,
.mobile-number-container select {
  width: 320px; /* Set width of input and select fields to 330px */
  height: 35px; /* Set height to 40px */
  margin-bottom: 10px; /* Adjust margin */
  border-color: #333;
}

.btn-submit {
  width: 320px; /* Set button width to 330px */
  height: 50px; /* Set button height to 40px */
  margin: 10px; /* Center button */
  margin-left: 5px !important;
  background-color: #00AEEF; /* Keep the existing background color */
}

.button-close {
  font-size: 16px !important; /* Reduce close button size for mobile */
}

.btn-close {
  display: none;
}
  /* popup */
  .query-form{
    margin-left: 0px !important;
    padding: 10px !important;
  }

  .input-group, .form-control-query {
    width: 320px;
    height: 30px;
    font-size: 14px;
    border-radius: 5px;
    margin-bottom: 10px !important;
}

.input-group.custom-width {
  width: 320px;
  height: 30px;
  font-size: 14px;
  border-radius: 5px;
  flex-wrap: nowrap;
  margin-left: 10px;
  margin-top: 20px;
  /* margin-bottom: 10px !important; */
}

#query1.form-control-query{
  width: 320px!important;
  height: 35px;
  margin-bottom: 10px !important;
  padding: 5px;
 }

 .black-calendar {
  width: 20px;
  height: 20px;
  margin-right: 0px;
  }

  .date-picker-wrapper{
    width: 320px !important;
  }

 #query1.form-control-query.date-picker,
 #query1.form-control-query.time-picker,
 #query1.form-select.mode{
  width: 320px!important;
  height: 35px !important;
  margin-bottom: 10px !important;
  padding: 5px;
 }

 #query2.mobile-number{
  width: 250px !important;
  height: 35px !important;
  border-radius: 0 5px 5px 0;
  padding: 5px;
 }
 #query3.form-control-query{
  width: 320px!important;
  height: 80px;
  padding: 5px;
 }
 .btn-submit{
  width: 320px!important;
  margin-left: 25px;
  margin-bottom: 0;
 }
 .request-batch{
  padding-bottom: 5px;
 }
 .request-form{
  margin-left: 5px !important;
  padding: 10px !important;
}

.country-code-dropdown {
  font-size: 12px;
  height: 30px !important;
  padding: 0 3px;
  width: 100px !important;
}

.country-flag {
  width: 20px;
  margin-right: 5px;
}
}

.country-code-dropdown {
  display: flex;
  align-items: center;
  font-size: 20px;
  height: 50px;
  padding: 0 6px;
  width: 120px;
  border-color: #b3b3b3 !important;
  display: flex; /* Ensure content is centered */
  align-items: center; /* Center icon and text vertically */
}

.country-flag {
  width: 20px;
  margin-right: 5px;
}

.input-group {
  display: flex;
  align-items: center;
}

.date-picker-wrapper{
  width: 520px ;
}

#query1.form-control-query.date-picker,
#query1.form-control-query.time-picker,
#query1.form-select.mode{
width: 520px;
height: 50px;
margin-bottom: 10px;
border: 1px solid #b3b3b3;
}

#query2.mobile-number{
  width: 410px;
  height: 50px;
  border-radius: 0 5px 5px 0;
  outline: none;
  border: 1px solid #b3b3b3;
  padding: 10px;
 }

#query1{
  width: 520px ;
  height: 50px ;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #b3b3b3;
}
.query-form{
  margin-left: 20px;
  padding: 20px;
}
#query2{
  width: 400px !important;
  border: 1px solid #b3b3b3;
}
#query3{
  width: 520px;
  height: 100px;
  border: 1px solid #b3b3b3;
}
.request-form{
  margin-left: 20px;
  padding: 20px;
}
@media (min-width:540px) and (max-width:1024px){
  #query2{
    width: 375px !important;
    height: 50px;
  }
}